import React from 'react';
import { Link } from 'react-router-dom';

const PlatformSection = ({items,commnHeader,commnTopLine}) => {
  return (<>
        <section className="our-platform-sec wow fadeInUp">
			<div className="container">
				<div className="cmn_hdr wht text-center">
					<h4> {commnTopLine}</h4>
					<h2> {commnHeader}</h2>
				</div>
				<div className="outr_platform">
                    {items.map((element) => {
                        const { id, image, title,description } = element;
                        return (<Link to="" className="invrstment-bx text-center" key={id}>
                            <div className="invst-icn floating">
                                <img src={image} alt=""/>
                            </div>
                            <div className="invst-txt">
                                <h3>{title}</h3>
                                <p>
                                    {description}
                                </p>
                            </div> </Link>
                        );
                    })}
				</div>
			</div>
		</section>
  </>);
};

export default PlatformSection;
