const TeamData = [
    {
      id:"1",
      image:"images/our-team-pic1.jpg",
      title:"Alia Peragallo",
      bottomTag:"Project Manager",
      description:"Consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    },
    {
      id:"2",
      image:"images/our-team-pic2.jpg",
      title:"Marty Owens",
      bottomTag:"Development Manager",
      description:"Consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    },
    {
      id:"3",
      image:"images/our-team-pic3.jpg",
      title:"Brendan Curtin",
      bottomTag:"Digital Marketing Manager",
      description:"Consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    }

    ]

    export default TeamData;