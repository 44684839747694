export const homeObjOne=
{
    topLine:'Exlussive access',
    headline:' A New Era for Real Estate Investment',
    img:'images/banner-pic.jpg',
    alt:'banner image'
}

export const abouObj=
{
    topLine:'About Us',
    commnHeadline:'Invest Smarter, Live',
    description:'Founded in 1995, CMK Companies is a leading real estate development company that continues to refine living experiences with its award-winning projects. In its relentless pursuit of inspired design for modern living, quality and value, CMK partners with forward-thinking architects and employs leading-edge materials and technologies. CMK’s uncompromising passion for impact and integrity fosters growth and enhancement of the surrounding communities.',
    buttonLabel:'Read More',
    img:'images/about-img.jpg',
    alt:'about pic'
}
