import React, { useState, useEffect } from 'react';
import HeroSection from '../../HeroSection';

import AboutSection from '../../AboutSection';
import { homeObjOne, abouObj } from './Data'
import { CommnHdr, CommnHdr2, CommnHdr3 } from '../../CommonHeaderData';

import PlatformData from '../../PlatformData';
import PlatformSection from '../../PlatformSection';
import PortfolioData from '../../PortfolioData';
import Portfolio from '../../Portfolio';
import MeetOurData from '../../MeetOurTeamData';
import MeetOurTeam from '../../MeetOurTeam';


const Home=() =>{
    const [items, setItems] = useState(PlatformData);
    const [portfoliodata, setPortFolioData] = useState(PortfolioData);
    const [team, setTeamData] = useState(MeetOurData);

    const getItemsData = () => {
        setItems(items);
        console.log(items);
    }
    useEffect(() => {
        getItemsData();
        // eslint-disable-next-line
    }, []);
    const getPortfolioData = () => {
        setPortFolioData(portfoliodata);
        console.log(portfoliodata);
    }
    useEffect(() => {
        getPortfolioData();
        // eslint-disable-next-line
    }, []);
    const getTeamData = () => {
        setTeamData(team);
        console.log(team);
    }
    useEffect(() => {
        getTeamData();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <div className='home__hero-section-outr'>
                <HeroSection {...homeObjOne} />
                <AboutSection {...abouObj} />
                <PlatformSection {...CommnHdr} items={items} />
                <Portfolio {...CommnHdr2} items={portfoliodata} />
                <MeetOurTeam {...CommnHdr3} teamData={team} />
            </div>
        </>
    );
}

export default Home;
