import React from 'react';

function Portfolio({ items,commnHeader,commnTopLine }) {
    return (<>
        <section className="investment_sec line_bg_aftr2 wow fadeInUp">
            <div className="container">
                <div className="cmn_hdr text-center">
                    <h4>{commnTopLine}</h4>
                    <h2> {commnHeader} </h2>
                </div>
                <div className="investment_portfolio_outr">
                    <div className="row align-items-center">
                        {items.map((element) => {
                            const { id, image, title, bottomTag } = element;
                            return (<div className="col-md-3 col-sm-6" key={id}>
                                <div className="portfolio-bx">
                                    <div className="portfolio_pic">
                                        <img src={image} alt="" />
                                    </div>
                                    <div className="porffolio_txt text-center">
                                        <h3>{title}</h3>
                                        <h4>{bottomTag}</h4>
                                    </div>
                                </div>
                            </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </section>
    </>);
}

export default Portfolio;
