

const PlatformData = [
    {
      id:"1",
      image:"images/investment-icn1.png",
      title:"Diligence",
      description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod"
    },
    {
      id:"2",
      image:"images/investment-icn2.png",
      title:"Acquisitions",
      description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod"
    },
    {
      id:"3",
      image:"images/investment-icn3.png",
      title:"Financing",
      description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod"
    },
    {
      id:"4",
      image:"images/investment-icn4.png",
      title:"Management",
      description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod"
    },
    {
      id:"5",
      image:"images/investment-icn5.png",
      title:"Trusted Partner Certainty of Execution",
      description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod"
    }
    ]

    export default PlatformData;