import React from 'react';
import { Link } from 'react-router-dom';
const Footer = () => {
  return( <>
  <footer className="footer">
			<div className="top-ftr-outr wow fadeInUp">
				<div className="container">
					<div className="top_ftr line_bg_aftr5">
						<h3>Subscribe to our newsletter today!</h3>
						<div className="email_bx">
							<div className="d-flex align-items-center">
								<div className="inner_em_bx">
									<div className="form-group">
										<input type="email" className="form-control" placeholder="Enter your email.."/>
									</div>
								</div>
								<div className="innr_sub_bx">
									<div className="form-group">
										<div className="submt">
											<input type="submit" value="SUBMIT NOW"/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="middle-ftr">
						<div className="row">
							<div className="col-md-4">
								<div className="ftr-menu">
									<div className="ftr-logo">
										<Link to="/"><img src="images/logo.png" alt=""/></Link>
									</div>
									<p>
										Founded in 1995, CMK Companies is a leading real estate development company that continues to refine living experiences with its award-winning projects. In its relentless pursuit of inspired design for modern living, quality and value.
									</p>

									<div className="copy_right d-view">
										<p>
											© 2021 <Link to="/">Real Estate Firm.</Link>
										</p>
									</div>
								</div>
							</div>
							<div className="col-md-3 commn-padd-120">
								<div className="ftr-menu">
									<div className="ftr-top-menu">
										<h3>QUICK LINKS</h3>
									</div>
									<ul className="ftr-btm-menu">
										<li>
											<Link to="/">Home </Link>
										</li>
										<li>
											<Link to="/">About Us </Link>
										</li>
										<li>
											<Link to="/">Our Platform </Link>
										</li>
										<li>
											<Link to="/">Our Team </Link>
										</li>
										<li>
											<Link to="/">Investment Portfolio </Link>
										</li>
										<li>
											<Link to="/">Contact Us</Link>
										</li>
									</ul>
								</div>
							</div>
					
							<div className=" col-md-3 ">
								<div className="ftr-menu ftr-pddng">
									<div className="ftr-top-menu">
										<h3>Contact Us</h3>
									</div>
									<p>
										Corporate Offices
										225 W. Ohio, 6th Floor
										Chicago, IL 60654
									</p>
									<p>
										P- 312.376.2020
									</p>
									<p>
										F- 312.376.2056
									</p>
								</div>
							</div>
							<div className=" col-md-2 ">
								
								<div className="ftr-menu">
									<div className="ftr-top-menu ">
										<h3>follow us on</h3>
									</div>
									<ul className="ftr-btm-menu scl-icon">
										<li>
											<i className="fa fa-facebook"></i><Link to="/">Facebook</Link>
										</li>
										<li>
											<i className="fa fa-twitter"></i><Link to="/">Twitter</Link>
										</li>
										<li>
											<i className="fa fa-linkedin"></i><Link to="/">Linked In</Link>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="copy_right m-view text-center">
				<p>
					© 2021 <Link to="/">Real Estate Firm.</Link>
				</p>
			</div>
		</footer>
  </>);
};

export default Footer;
