import React from 'react';
import { Link } from 'react-router-dom';

const AboutSection = ({ img, alt, topLine, commnHeadline, description, buttonLabel }) => {
	return (
		<>
			<section className="about_sec line_bg_aftr order-change">
				<div className="main-outr">
					<div className="container-fluid">
						<div className="row">
							<div className="about-outr">
								<div className="about-pic">
									<div className="abt_pic_inner wow fadeInLeft">
										<img src={img} alt={alt} />
									</div>
								</div>
								<div className="about-txt padd-right-55-P padd-right-195 abt-txt-bg">
									<div className="cmn_hdr text-left">
										<h4>{topLine}</h4>
										<h2> {commnHeadline} </h2>
									</div>
									<p>
										{description}
									</p>
									<Link to="" className="read_more">{buttonLabel} <img src="images/blue-arrow.png" alt="" /> </Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default AboutSection;

