import React from 'react';
// import React, {useState} from 'react';
import { Link } from 'react-router-dom';
// import { MdFingerprint } from 'react-icons/md';
// import { FaBars, FaTimes } from 'react-icons/fa';

// import { Button } from './Button';
// import './Navbar.css';
// import { IconContext } from 'react-icons/lib';
import logo  from '../logo.png'

const Navbar = () => {
    return (
        <>
        <header>
        <div className='header-outr'>
            <div className='container'>
            <nav className="navbar navbar-expand-md ">
                <Link className="navbar-brand logo_area" to="/"> <img className="img-fluid" src= {logo} alt=""/>  </Link>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav">
                        <li className="nav-item active">
                            <Link className="nav-link" to="/">Home <span className="sr-only">(current)</span></Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/about">About Us</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/our-approch">Our Approach</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/our-team">Our Team</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/investment">Investment Portfolio</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/contact">Contact Us</Link>
                        </li>
                    </ul>

                </div>
            </nav>
            </div>
        </div>
        </header>
        </>

    );
};

export default Navbar;
