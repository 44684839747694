
export const CommnHdr= {
    commnTopLine:'INVESTMENT APPROACH',
    commnHeader:'Our Platform',
  }
  export const CommnHdr2= {
    commnTopLine:'Portfolio',
    commnHeader:'Investment Portfolio Portal',
  }

  export const CommnHdr3= {
    commnTopLine:'OUR TEAM ',
    commnHeader:'Meet Our Team',
    commnHeaderDesc:"Consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip."
  }