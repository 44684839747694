// import logo from './logo.svg';
import './App.css';
import './Responsive.css';
import Navbar from './components/Navbar';
import {BrowserRouter as Router,Routes,Route
} from "react-router-dom";
// import { Route } from "react-router-dom";

import Footer from './components/Footer';
import Home from './components/pages/HomePage/Home';
// import Aboutpage from './components/Aboutpage';
function App() {
  return (
    <Router>
    <div className="App">
     
        <Navbar />
        <Routes>
          <Route path="/" exact element={<Home/>} />
          {/* <Route path="/about" element={<Aboutpage/>}/> */}
        </Routes>
        <Footer/>
      
    </div>
    </Router>
  );
}

export default App;
