import React from 'react';
// import { Button } from './Button';
import { Link } from 'react-router-dom';
// import './HeroSection.css';

// import { FaAngleRight } from 'react-icons/fa';
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';


function HeroSection({
 headline,img, alt
}) {
    return (
        <>

        <section className="banner-sec">
			<div className="banner-img">
				<img className="full-banner" src={img} alt={alt} />
			</div>
			<div className="banner_outr" data-wow-delay="0.2s">
				<div className="container">
					<div className="banner-inner">
						<div className="banner_txt text-center wow fadeInUp">
							<h1>{headline} </h1>
							<Link to="" className="b_Arrow"> <HiOutlineArrowNarrowRight /> </Link>
						</div>
					</div>
				</div>
			</div>
		</section>   
        </>
    );
}

export default HeroSection;
