
  const PortfolioData = [
      {
        id:"1",
        image:"images/portfolio-pic1.jpg",
        title:"Imprint",
        bottomTag:"STARTUP"
      },
      {
        id:"2",
        image:"images/portfolio-pic2.jpg",
        title:"Coeval",
        bottomTag:"STARTUP"
      },
      {
        id:"3",
        image:"images/portfolio-pic3.jpg",
        title:"Riverline",
        bottomTag:"STARTUP"
      },
      {
        id:"4",
        image:"images/portfolio-pic4.jpg",
        title:"Sailrock",
        bottomTag:"STARTUP"
      }
      ]

      export default PortfolioData;