import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { HiPlusSm } from 'react-icons/hi';
import { TiSocialFacebook, TiSocialTwitter, TiSocialLinkedin } from 'react-icons/ti';

function MeetOurTeam({ teamData, commnTopLine, commnHeader, commnHeaderDesc }) {

    function ReadMore({ children, maxCharacterCount = 100 }) {
        const text = children;
        const [isTruncated, setIsTruncated] = useState(true);

        const resultString = isTruncated ? text.slice(0, 0) : text;
        const toggleIsTruncated = () => {
            setIsTruncated(!isTruncated);
        }

        return (
            <div className="has-text-left">
                <p>{resultString}</p>
                <Link to="/" onClick={toggleIsTruncated} className='plus_btn'><HiPlusSm /></Link>
            </div>
        )
    }

    return (<>
        <section className="meet_our_team_sec line_bg_aftr3">
            <div className="container">
                <div className="cmn_hdr text-center">
                    <h4>{commnTopLine} </h4>
                    <h2>{commnHeader}</h2>
                    <p>
                        {commnHeaderDesc}
                    </p>
                </div>
                <div className="our_meet">
                    <div className="row">

                        {teamData.map((element) => {
                            const { id, image, title, bottomTag, description } = element;
                            return (

                                <div className="col-md-4" key={id}>
                                    <div className="innr_team_bx">
                                        <div className="team_pic">
                                            <img src={image} alt="" />
                                        </div>
                                        <div className="our_team_des text-center" id={id}>
                                            <h3>{title}</h3>
                                            <h4>{bottomTag}</h4>
                                            <ReadMore>
                                                {description}
                                            </ReadMore>
                                            <ul>
                                                <li>
                                                    <Link to=""><TiSocialFacebook /></Link>
                                                </li>
                                                <li>
                                                    <Link to=""><TiSocialTwitter /></Link>
                                                </li>
                                                <li>
                                                    <Link to=""><TiSocialLinkedin /></Link>
                                                </li>
                                            </ul>
                                            {/* <Link to="" className="plus_btn toggle" id="bttn1" onClick={onClickHandler} > <HiPlusSm /></Link> */}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="text-center">
                    <Link to="" className="btn btn-primary cmn_btn views-all">View All Teams</Link>
                </div>
            </div>
        </section>
    </>);
}

export default MeetOurTeam;
